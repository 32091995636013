<div class="p-3">
    <div class="row m-0 ">
        <div class="col-12 p-0 d-flex align-items-center justify-content-between  border-b-1 pb-2">
          <h4 class="m-0 text-primary d-flex align-items-center" >
            Are you sure?
          </h4>

          <span class="cursor" (click)="closeform(0)" matTooltip="Close">
            <mat-icon>close</mat-icon>
          </span>
        </div>
    </div>
    <div class="row m-0 border-b-1">
        <div class="col-lg-12 p-0">
            <h5 class="m-0 py-3">Do you really want to delete these record?</h5>
        </div>
    </div>
    <div class="d-flex justify-content-center pt-3">
       
        <button type="submit" mat-button matTooltip="Delete" class="me-2 light-bg-acent" (click)="deleteRecord()"><span class="mdi mdi-check-all mx-1"></span>Delete</button>
        <button type="button"  mat-button color="primary" class=" light-bg-primary" matTooltip="Cancel" (click)="closeform(0)"><span class="mdi mdi-close mr-1"></span>Cancel</button>
      </div>
</div>

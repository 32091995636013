import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { Router, RouterOutlet } from '@angular/router';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
@Component({
  selector: 'app-notficationsnack',
  standalone: true,
  imports: [
    CommonModule, SharedModule, RouterOutlet
  ],
  templateUrl: './notificationsnack.html',
  styleUrl: './notficationsnack.component.css'
})
export class NotficationsnackComponent { 
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any,public router:Router, private _snackRef: MatSnackBarRef<NotficationsnackComponent>,) {
   }
   openNotification(){

    this.router.navigate([this.data.note_url])
   // this.router.navigateByUrl(this.data.url);
    this._snackRef.dismiss();
   }
   closeNotification(){
    this._snackRef.dismiss();
   }
}
